import React from 'react';
const PrivacyPolicy = () => {
    return (
        <div className='container'>
            <h1 class="text-3xl text-center mt-12">Privacy Policy for Utang Notifier</h1>
            <p class="mt-4">At Utang Notifier, accessible from https://delacruztechnologies.com, one of our main priorities is
                the privacy of our visitors. This Privacy Policy document contains types of information that is collected and
                recorded by Utang Notifier and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact
                us.</p>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards
                to the information that they shared and/or collect in https://delacruztechnologies.com. This policy is not
                applicable to any information collected offline or via channels other than this website. Our Privacy Policy was
                created with the help of the Free Privacy Policy Generator.</p>
            <h2 class="text-2xl mt-8">Information we collect</h2>
            <p>Information You Provide. When you register to the Site, use it, complete forms, participate in skills tests or
                surveys, or use the Site as collaborator, and/or register to our affiliate or influencer or similar program, we
                ask you to provide certain personal information, including a valid email address, Facebook or Google account
                login details and username. We will also ask you to provide or otherwise collect additional information, such
                as, your name, profile details, physical address or billing information, telephone number or other contact
                details, transactional information, payment information (for example, in certain cases we process your payment
                method and credit card number), taxpayer information and forms, details about other social networks linked
                accounts, details about your listed gigs, purchases, education, profession and expertise, information and files
                uploaded by you to the Site, and additional authentication information (such as copies of your government issued
                ID, passport, or driving license, as permitted by applicable laws and as detailed in our Seller Help Center at
                “Verifying Your Identity”). We also collect information about or contained in your communications with Utang
                Notifier as well as any of your posts on our blogs or forums and your communication with other users of Utang
                Notifier.</p>
            <p>Information We Collect Automatically. We collect information while you access, browse, view or otherwise use the
                Site. In other words, when you access the Site we collect personal information on your usage of the Site,
                including transactions and communications with other users through the Site, your searches, the pages you
                visited, as well as other actions on Site. We also, collect, use and process the information relating to such
                usage, including geo-location information, IP address, device and connection information, browser information
                and web-log information, and the URLs of the web pages you’ve viewed or engaged with before or after using the
                Site. We also collect and process information relating to the use of cookies and similar technologies, as
                detailed below. We use that information to provide you our services, enhance user experience, personalize your
                browsing experience as well as monitor the Site for preventing fraud and inappropriate content or behaviour.</p>
            <p>Additionally, in order to improve your online experience at Utang Notifier, we have implemented impression
                reporting. While you view our ads, we gather user Global Unique Identifier, HTTP request data like, user agent,
                IP, host, URL, country/continent from which request made, browser info, device/operating system/operating system
                version.</p>
            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be
                made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you contact us directly, we may receive additional information about you such as your name, email address,
                phone number, the contents of the message and/or attachments you may send us, and any other information you may
                choose to provide.</p>
            <p>When you register for an Account, we may ask for your contact information, including items such as name, company
                name, address, email address, and telephone number.</p>
            <h2 class="text-2xl mt-8">How Do We Use The Information Collected?</h2>
            <div>
                <ul>We will use your personal information for the following purposes:</ul>
                <li>to provide you with quality service and security, to operate the Site, to perform our obligations to you and
                    to develop and improve our service. For example, we use personal information to verify your identity. We
                    also use this information to establish and set up your account, verify or re-issue a password, log your
                    activity, enable your communications with other members, provide customer support and contact you from time
                    to time. The information helps us to develop and improve our services, to understand and analyse our
                    performance as well as your preferences and performance and to customize and personalize our service and
                    enhance your experience (including by making Gig suggestions, ranking search results, etc.).</li>
                <li>to ensure marketplace integrity, prevent fraud and maintain a safe and secure marketplace and services. For
                    example, we use personal information to monitor, track and prevent fraudulent activities and other
                    inappropriate activities, monitor content integrity, conduct security investigations and risk assessments,
                    verify or authenticate information provided by you, enforce our Terms of Service and comply with applicable
                    laws. We conduct certain behavioural analytics to achieve the above objectives and in limited cases, if we
                    detect activity that we think poses a risk to the Utang Notifier marketplace, other users, our community, or
                    third parties, automated processes may restrict or limit your ability to use Utang Notifier. If you would
                    like to challenge any such decision, please contact us at https://delacruztechnologies.com.</li>
                <li>to contact you, as requested by you or as otherwise approved by you or permitted according to this Policy.
                </li>
                <li>to promote and advertise the Site, our services and the Utang Notifiermarketplace. For example, we use the
                    information collected from you for the purpose of sending direct marketing messages (as detailed below), to
                    show you information that may be of interest to you, to organize and facilitate referral programs, contests
                    or other promotional activities or events.</li>
                <li>We use your personal information to send you direct marketing communications about our products, services or
                    promotions from Utang Notifierthat may be of interest to you or our related services. This may be via email,
                    post, SMS, telephone or targeted online advertisements.</li>
                <li></li>
            </div>
            <h2>How Long Do We Keep Personal Information?</h2>
            <ul>We apply a general rule of keeping personal information only for as long as is required to fulfil the purpose
                for which it was collected. However, in some circumstances, we will retain your personal information for longer
                periods of time. We will retain personal information for the following purposes:</ul>
            <li>as long as it is necessary and relevant for our operations, e.g. so that we have an accurate record of your
                dealings with us in the event of any complaints or challenge; and</li>
            <li>in relation to personal information from closed accounts to comply with applicable laws, prevent fraud, collect
                any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Site terms
                and take other actions as permitted by law.</li>
            <h2 class="text-2xl mt-8">Cookies</h2>
            <p>We use cookies and similar technologies (such as web beacons, pixels, tags, and scripts) to improve and
                personalize your experience, provide our services, analyze website performance and for marketing purposes. To
                learn more about how we and our third party service providers use cookies and your control over these Cookies,
                please see our Cookie Policy.</p>
            <h2 class="text-2xl mt-8">Our Advertising Partners</h2>
            <ul>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each
                of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we
                hyperlinked to their Privacy Policies below.</ul>
            <li>Google https://policies.google.com/technologies/ads</li>
            <li>Facebook</li>
            <li>Unity</li>
            <li>InMobi</li>
            <li>Affiliate Marketing</li>
            <li>Others</li>
            <h2>Advertising Partners Privacy Policies</h2>
            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of
                https://delacruztechnologies.com.</p>
            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in
                their respective advertisements and links that appear on https://delacruztechnologies.com, which are sent
                directly to users' browser. They automatically receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content
                that you see on websites that you visit.</p>
            <p>Note that https://delacruztechnologies.com has no access to or control over these cookies that are used by
                third-party advertisers.</p>
            <h2 class="text-2xl mt-8">Children's Information</h2>
            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and
                guardians to observe, participate in, and/or monitor and guide their online
                activity.https://delacruztechnologies.com does not knowingly collect any Personal Identifiable Information from
                children under the age of 13.</p>
            <p>If you think that your child provided this kind of information on our website, we strongly encourage you to
                contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
            <h2 class="text-2xl mt-8">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of
                personal data that a business has collected about consumers.</p>
            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
                please contact us.</p>
            <h2 class="text-2xl mt-8">Specific Provisions for California Residents</h2>
            <p>This section of the Policy applies to you, if you are a California resident. During the last twelve (12) months
                we have collected the following categories of personal information from users:</p>
            <p>Information that you chose to upload or otherwise provided by you toUtang Notifier, which may include: (i)
                Identifiers and personal information, such as name, postal addresses, online identifiers, email addresses,
                passport number or driving license number, social security number; (ii) characteristics of protected
                classifications, such as gender; facial image; audio, electronic or similar information; (iii) professional or
                employment-related information; (iv)education information; (v) commercial information; (vi) Audio or other
                sensory information, for example if you provide audio-based services on Utang Notifier.</p>
            <p>Information we collect when you use Utang Notifier, including (i) Identifiers and personal information, such as
                online identifiers, internet protocol (IP) addresses, access device and connection information such as browser
                type, version, and time zone setting and browser plug-in types and versions; (ii) commercial information,
                including products or services purchased, obtained, or considered, or other purchasing or consuming histories or
                tendencies; (iii) Internet or other electronic network activity information, including, but not limited to
                log-in and log-out time, the duration of sessions, the content uploaded and downloaded, viewed web-pages or
                specific content on web-pages, activity measures; (iv) Location information.</p>
            <p>Information that we collect or receive from third parties, such as service providers, advertisers, and
                third-party accounts you link withUtang Notifier, including: (i) Identifiers and personal information, such as
                name, online identifiers, email addresses, internet protocol (IP) addresses, access device and connection
                information such as browser type, version, and time zone setting and browser plug-in types and versions; (ii)
                Professional or employment-related information; (iii) Internet or other electronic network activity information,
                including, but not limited to log-in and log-out time, the duration of sessions, the content uploaded and
                downloaded, viewed web-pages or specific content on web-pages, activity measures; (iv)Commercial information;
                and (v) Location information.</p>
            <p>Inferences drawn from any of the information identified above to create a profile about you.</p>
            <p>We use the personal information that we collect or receive for the business purposes as described above under the
                Section titled “How Do We Use the Information Collected?”.</p>
            <h2 class="text-2xl mt-8">GDPR Data Protection Rights</h2>
            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to
                the following:</p>
            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee
                for this service.</p>
            <p>The right to rectification – You have the right to request that we correct any information you believe is
                inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
            </p>
            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal
                data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to our processing of your personal data, under
                certain conditions.</p>
            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to
                another organization, or directly to you, under certain conditions.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
                please contact us.</p>
        </div>
    )
}
export default PrivacyPolicy;