import React from 'react'
import Slideshow from './slideshow';
import Footer from './footer';
import "./index.css";
const Dashboard = ()=> {
    return (
        <div>
            <Slideshow/>
            <Footer/>
        </div>
    )
}
export default Dashboard;